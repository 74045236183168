
export interface IMenuItem {
	name: string,
	uri: string,
	isCurrent: boolean,
	id: string,
	isExpanded: boolean,
	hasChildren: boolean,
	children: IMenuItem[],
	level: number
}
export interface IMenu {
	menuItems: IMenuItem[]
}
export interface ITranslations {
	mainMenu: string,
	openMenu: string,
	closeMenu: string
}
export interface ITopLinks {
	displayName: string,
	uri: string,
	url: string
}

export interface IState {
	menuItems: IMenuItem[]
	apiURLs: {
		getChildren: string
	},
	translations: ITranslations,
	className: string,
	topLinks: ITopLinks[],
}

const deafultState: IState = {	
	menuItems: [],	
	apiURLs: {
		getChildren: ''
	},
	translations: {
		mainMenu: '',
		openMenu: '',
		closeMenu: ''
	},
	className: '',
	topLinks: [],
}

export default deafultState;