
import Vue from 'vue'
import { IMenuItem } from '../store/state'
import MenuItem from './MenuItem.vue'
import { ITranslations } from '../store/state'
import TopLinks from './TopLinks.vue'

interface MenuData {
    visible: boolean;
}
interface MenuMethods {
    toggleMenu(): void
}
interface MenuComputed {
    menuItems: any
    translations: ITranslations,
    className: string,
}

interface MenuProps {
    menuItems: IMenuItem[]
}

const component: Function = Vue.extend<MenuData, MenuMethods, MenuComputed, MenuProps>({
    name: 'Menu',
    data() {
        return { 
            visible: false
        }
    }, 
    computed: {
        menuItems() {
            return this.$store.getters.menuItems;
        },
        translations() {
			return this.$store.getters.translations
		},
        className() {
            return this.$store.getters.className;
        },
	},
    methods: {
        toggleMenu() {
            this.visible = !this.visible;
            const body = document.querySelector("body");
            body ? body.classList.toggle("fixed") : null;
        }
    },
    components: {
		MenuItem,
        TopLinks
	},
})

export default component
