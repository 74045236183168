import { Getter } from "vuex";
import state, { IState } from './state';

const getChildrenUrl: Getter<IState, IState> = (state) => state.apiURLs.getChildren;
const menuItems: Getter<IState, IState> = (state) => state.menuItems;
const translations: Getter<IState, IState> = (state) => state.translations;
const className: Getter<IState, IState> = (state) => state.className;
const topLinks: Getter<IState, IState> = (state) => state.topLinks;

export default {
	getChildrenUrl,
	menuItems,
	translations,
	className,
	topLinks,
}
